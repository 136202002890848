html {
     height: 100%;
     min-height: 100%;
	 background-color: #282c34;
}

.App {
  	text-align: center;
  	justify-content: center;
	min-height: 100%;
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	background-color: #282c34;
	color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.divStyle {
  justify-content: center;
  align-items: center;
  align-self: center;
}

.letter-button {
  background: aquamarine;
  border: none;
  border-radius: 0.5em;
  font-size: 2em;
  padding: 0.5em;
  margin-right: 0.2em; 
  margin-left: 0.2em; 
  margin-top: 0.2em;
  margin-bottom: 0.2em;
  width: 2em;
  color: black;
}

.middle-letter {
	background: cornflowerblue;
	border: none;
	border-radius: 0.5em;
	font-size: 2em;
	padding: 0.5em;
	margin-right: 0.2em; 
	margin-left: 0.2em; 
	margin-top: 0.2em;
	margin-bottom: 0.2em;
	width: 2em;
	color: black;
}

.letters-div {
	position: absolute;
	top: 25%;
	left: 0;
	right: 0;
	width: 55%;
	margin-bottom: 0.5em;
	margin: 0 auto;
}

.guesses-div {
	position: absolute;
	top: 80%;
	width: 80%;
}

.guesses {
	color: chartreuse;
	font-size: 1.5em;
}

.current-guess {
	position: absolute;
	top: 5%;
	font-size: 2em;
	left: 0;
	right: 0;
}

.close-button {
	position: absolute;
	background: antiquewhite;
	border: none;
	border-radius: 1.5em;
	font-size: 1.5em;
	padding: 0.5em;
	margin-top: 1em;
	margin-right: 0.2em; 
	margin-left: 0.2em; 
	width: 2em;
	left: 105%;
	top: 25%;
	color: black;
}

.question-icon {
	position: absolute;
	display: block;
	transform: scaleX(-1);
	left: 1em;
	font-size: 2em;
	top: 0.5em;
}

.chart-icon {
	position: absolute;
	display: block;
	left: 1em;
	font-size: 2em;
	top: 0.5em;
	margin-left: 1.5em;

}

.coffee-icon {
	position: absolute;
	display: block;
	transform: scaleX(-1);
	left: 1em;
	font-size: 2em;
	bottom: 0.5em;

}

.tarkeeb-title {
	position: absolute;
	padding: 0.5em;
	right: 1.5em;
	top: -0.2em;
	margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
	width: 5em;
}

.score {
	position: absolute;
    top: 1em;
	color: darkseagreen;
    font-size: 1em;
}

.progress-bar {
	position: absolute !important;
    top: 50% !important;
    transform: rotate(270deg) !important;
	left: -3em !important;
    width: 15em !important;
	height: 1em !important;
}

.modalStyle {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 400;
	background-color: white;
	border: 2px solid #000;
	box-shadow: 24;
	p: 4;
	outline: 0;
	text-align: right;
}

.align-center {
	text-align: center;
}

.modal-stat-text {
	margin-bottom: 0.3em;
}

.winning-text {
	color: cornflowerblue;
	margin-top: 0.2em;
	margin-bottom: 0.2em;
}

.stats-letter-blue {
	background: cornflowerblue;
	border: none;
	border-radius: 0.5em;
	font-size: 1em;
	padding: 0.2em;
	margin-right: 0.1em; 
	margin-left: 0.1em; 
	margin-top: 0.1em;
	margin-bottom: 0.1em;
	width: 3em;
	color: black;
}

.stats-letter-green {
	background: aquamarine;
	border: none;
	border-radius: 0.5em;
	font-size: 1em;
	padding: 0.3em;
	margin-right: 0.1em; 
	margin-left: 0.1em; 
	margin-top: 0.1em;
	margin-bottom: 0.1em;
	width: 3em;
	color: black;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width:910px) {
	.guesses-div {
		position: absolute;
		top: 80%;
	}
}

@media (max-width:500px) {
	.letters-div {
		top: 25%;
		width: 60%;
	}
	
	.letter-button {
		background: aquamarine;
		border: none;
		border-radius: 0.5em;
		font-size: 1.5em;
		padding: 0.5em;
		margin-right: 0.2em; 
		margin-left: 0.2em; 
	}

	.middle-letter {
		background: cornflowerblue;
		border: none;
		border-radius: 0.5em;
		font-size: 1.5em;
		padding: 0.5em;
		margin-right: 0.2em; 
		margin-left: 0.2em; 
	}

	.close-button {
		background: antiquewhite;
		border: none;
		border-radius: 1.5em;
		font-size: 1em;
		padding: 0.5em;
		margin-top: 1em;
		margin-right: 0.2em; 
		margin-left: 0.2em; 
	}

	.guesses-div {
		position: absolute;
		top: 60%;
	}

	.close-button {
		top: 35%;
	}

	.tarkeeb-title {
		right: 1em;
	}

	.current-guess {
		top: 10%;
	}

	.progress-bar {
		top: 35% !important;
		left: -5em !important;
	}

	.score {
		font-size: 1.5em;
	}
}
